<template>
    <b-card tag="div" class="shadow h-100 wl-card wl-card__product w-100" :class="[`bg-${variant}`, (noPadding) ? 'wl-card--no-padding' : 'p-2' ]">

    <div class="wl-card__product__image d-flex justify-content-center wl-card__product__image--full">

      <div v-if="badgeText" class="wl-card__product__price-badge">
        <span v-html="badgeText"></span>
        <!--<dumbBubbleAnimation fill-color="cta"/>-->
      </div>
      <b-img :src="productImage" v-if="productImage" fluid :alt="productTitle" @click="$emit('submit', { url: productCtaLink, type: productType, product: product})"/>
    </div>
    <b-container fluid="xxl" class="mb-1 mt-5">
        <b-row>
            <b-col cols="12">
                <div class="p-2 mt-3">
                    <h6 class="font-light">{{ productType }}</h6>
                    <h5>{{ productTitle }}</h5>
                    <p v-html="productDescription" v-if="productDescription"></p>
                    <p><small>{{ productPurchased }}</small></p>
                </div>
               
            </b-col>
        </b-row>
        
    </b-container>
     <div class="d-flex justify-content-center mt-auto mb-5" v-if="productBtnLabel != ''">

        <b-button v-if="(productPseudoPrice == 0 && productPrice == 0) || productIsMyCourse" @click="$emit('submit', { url: productCtaLink, type: productType, product: product})"  type="button" variant="cta" class="button-cta mt-4">
            {{ productBtnLabel }}
        </b-button>

        <b-button-group v-else class="mt-4">

            <b-button @click="$emit('submit', { url: productCtaLink, type: productType, product: product})"  type="button" variant="cta" class="button-cta">
                {{ $t('BUY_LEARNING_CONTENT') }}
            </b-button>
            <b-button type="button" variant="cta-secondary" class="button-cta-secondary append" v-if="productPseudoPrice > 0">
                <span class="old-price small-text light-weight strike-through"><span v-html="$utils.formatPseudoPrice(productPseudoPrice)"></span></span>
                <span class="discount small-text">{{$t('PRICE_DISCOUNT', '', {discount: $utils.getPercent(productPseudoPrice, productPrice)})}}</span>
            </b-button>
        </b-button-group>
       
     
    
    </div>
    </b-card>
</template>


<script>
import hocCard from '@/components/hoc/hocCard';
//import dumbBubbleAnimation from '@/components/dumb/dumbBubbleAnimation';
import Vue from 'vue'

export default {
  name: "ProductCard",
  emits: ['submit'],
  mixins: [hocCard],
  components: {
    //dumbBubbleAnimation,
},
  props: {
    product:{
      type: Object,
    },
    productId:{
        type: String,
        default:''
    },
    productTitle: {
        type: String,
        default:''
    },
    productType: {
        type: String,
        default:''
    },
    productImage: {
        type: String,
        default:''
    },
    productPrice: {
        type: String,
        default:''
    },
    productPseudoPrice: {
        type: String,
        default:''
    },
    productAction: {
        type: String,
        default:''
    },
    productCtaLink:  {
        type: String,
        default:''
    },
    productBtnLabel: {
        type: String,
        default:''
    },
    productDescription: {
        type: String,
        default:''
    },
    productPurchased: {
      type: String,
      default:''
    },
    productIsMyCourse: {
      type: Number,
      default: 0
    },
    badgeText:  {
      type: String,
      default:''
    },
    id: {
        type: String,
        default: () => Vue.utils.generateRandomHash(10)
    }

  },
  methods: {
   
  }
}
</script>