<template>
  <div id="app" class="app" :class="{ 'app--full-height': $route.name.match('error-404') }">
    <div class="page  w-100 d-flex" :class="{ 'page--error': $route.name.match('error-404') }">

      <div class="router-container  p-xl-5">
        <AppLayout>
          <router-view />
        </AppLayout>
      </div>
     </div>
   
     <div class="svg-filter">
       <!-- filters -->
       <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
          <defs>
            <filter id="goo">
                <feGaussianBlur in="SourceGraphic" result="blur" stdDeviation="5" />
                <feColorMatrix in="blur" type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="hightContrastGraphic" />
                <feComposite in2="hightContrastGraphic" in="SourceGraphic" operator="atop" />
            </filter>
           
          </defs>
      </svg>
    </div>
  </div>


</template>

<script>
import AppLayout from "@/layouts/AppLayout"; 
import {mapActions, mapGetters, mapMutations} from "vuex";
import axios from "axios";

export default {
  name: 'App',
  components: {
    AppLayout 
  },
  data() {
    return {
      API_URL: process.env.VUE_APP_API,
      interval: 0,
      intervalCounter: 0,
      //current_route: {},
    }
  },
  mounted: function () {
    // Attach event listener to the root vue element
    //document.addEventListener('click', this.onClick);

    this.getTokenData();

    //this.currentRoute();

    this.$root.$on('track', (data) => {
      this.trackData(data);
    });
  },
  beforeDestroy: function () {
    //document.removeEventListener('click', this.onClick)
  },
  computed: {
    ...mapMutations([
    ]),
    ...mapActions([
      'setIsLoggedIn',
      'setSystemInfo',
    ]),
    ...mapGetters([
      'getIsLoggedIn',
      'getToken',
      'getTokenMd5',
      'getSystemInfo',
      'getFromRoute',
      'getToRoute',
    ]),
    checkLogin() {

      this.loginCheckInterval();

      return true;
    },
  },
  methods: {
    async getTokenData() {
      await axios.post(
          this.API_URL,
          {
            type: 'get_token',
          }
      ).then((response) => {
        //console.log(response);

        if (response.data.token) {
          this.$store.dispatch('setToken', response.data.token);
          this.$store.dispatch('setTokenMd5', response.data.token_md5);

          this.systemInfo();
        }

      });

    },
    systemInfo() {

      let appVersion = '';
      let appName = '';
      let appCodeName = '';
      let userAgent = '';
      let language = '';
      let oscpu = '';
      let platform = '';

      if ("appVersion" in navigator) appVersion = navigator.appVersion;
      if ("oscpu" in navigator) oscpu = navigator.oscpu;
      if ("appName" in navigator) appName = navigator.appName;
      if ("appCodeName" in navigator) appCodeName = navigator.appCodeName;
      if ("userAgent" in navigator) userAgent = navigator.userAgent;
      if ("language" in navigator) language = navigator.language;
      if ("platform" in navigator) platform = navigator.platform;

      var nVer = appVersion;
      var nAgt = userAgent;
      var browserName  = appName;
      var fullVersion  = '' + parseFloat(appVersion);
      var majorVersion = parseInt(appVersion,10);
      var nameOffset,verOffset,ix;

      let to = this.getToRoute;
      let locationOrigin = window.location.origin;

      let target = locationOrigin + to.fullPath;

      // In Opera, the true version is after "Opera" or after "Version"
      if ((verOffset=nAgt.indexOf("Opera"))!=-1) {
        browserName = "Opera";
        fullVersion = nAgt.substring(verOffset+6);
        if ((verOffset=nAgt.indexOf("Version"))!=-1)
          fullVersion = nAgt.substring(verOffset+8);
      }

      // In MSIE, the true version is after "MSIE" in userAgent
      else if ((verOffset=nAgt.indexOf("MSIE"))!=-1) {
        browserName = "Microsoft Internet Explorer";
        fullVersion = nAgt.substring(verOffset+5);
      }

      // In Chrome, the true version is after "Chrome"
      else if ((verOffset=nAgt.indexOf("Chrome"))!=-1) {
        browserName = "Chrome";
        fullVersion = nAgt.substring(verOffset+7);
      }

      // In Safari, the true version is after "Safari" or after "Version"
      else if ((verOffset=nAgt.indexOf("Safari"))!=-1) {
        browserName = "Safari";
        fullVersion = nAgt.substring(verOffset+7);
        if ((verOffset=nAgt.indexOf("Version"))!=-1)
          fullVersion = nAgt.substring(verOffset+8);
      }

      // In Firefox, the true version is after "Firefox"
      else if ((verOffset=nAgt.indexOf("Firefox"))!=-1) {
        browserName = "Firefox";
        fullVersion = nAgt.substring(verOffset+8);
      }

      // In most other browsers, "name/version" is at the end of userAgent
      else if ( (nameOffset=nAgt.lastIndexOf(' ')+1) <
          (verOffset=nAgt.lastIndexOf('/')) )
      {
        browserName = nAgt.substring(nameOffset,verOffset);
        fullVersion = nAgt.substring(verOffset+1);
        if (browserName.toLowerCase()==browserName.toUpperCase()) {
          browserName = navigator.appName;
        }
      }

      // trim the fullVersion string at semicolon/space if present
      if ((ix=fullVersion.indexOf(";"))!=-1)
        fullVersion=fullVersion.substring(0,ix);
      if ((ix=fullVersion.indexOf(" "))!=-1)
        fullVersion=fullVersion.substring(0,ix);

      majorVersion = parseInt(''+fullVersion,10);
      if (isNaN(majorVersion)) {
        fullVersion  = ''+parseFloat(navigator.appVersion);
        majorVersion = parseInt(nVer,10);
      }

      var isMobile = (/Mobile/i.test(navigator.userAgent));

      var device = 'Desktop';
      if (isMobile) {
        device = 'mobile';
      }

      var systemData = {
        'system': nVer,
        'appName': appName,
        'appCodeName': appCodeName,
        'browser': browserName,
        'browserVersion': fullVersion,
        'browserMajorVersion': majorVersion,
        'userAgent': userAgent,
        'language': language,
        'oscpu': oscpu,
        'platform': platform,
        //'navigator': navigator,
        'browserWidth': this.getWidth(),
        'browserHeight': this.getHeight(),
        'device': device,
        'target': target,
        'title': document.title,
      };

      //console.log('systemInfo');
      //console.log(systemData);
      this.$store.dispatch('setSystemInfo', systemData);

      //todo: local development
      var cookie = this.$store.getters.getToken;

      axios.post(
          this.API_URL,
          {
            type: 'system_data',
            cookie: cookie,
            system_data: systemData,
          }
      );
    },
    getWidth() {
      return Math.max(
          document.body.scrollWidth,
          document.documentElement.scrollWidth,
          document.body.offsetWidth,
          document.documentElement.offsetWidth,
          document.documentElement.clientWidth
      );
    },
    getHeight() {
      return Math.max(
          document.body.scrollHeight,
          document.documentElement.scrollHeight,
          document.body.offsetHeight,
          document.documentElement.offsetHeight,
          document.documentElement.clientHeight
      );
    },
    currentRoute() {
      //console.log('route:', this.$router.currentRoute);
      this.current_route = this.$router.currentRoute;
    },
    onClick: function (ev) {
      console.log(ev);
      console.log(ev.offsetX, ev.offsetY);
      this.clickData(ev);
    },
    clickData(event) {

      let data = {};

      data.element_type = event.target.tagName;
      data.description = event.target.textContent;
      data.link = event.target.src;
      data.alt = event.target.alt;
      data.title = event.target.title;
      data.pageX = event.pageX;
      data.pageY = event.pageY;
      data.screenX = event.screenX;
      data.screenY = event.screenY;
      data.offsetX = event.offsetX;
      data.offsetY = event.offsetY;
      data.target = event.originalTarget.baseURI;
      data.base_name = '';
      data.base_path = '';
      data.target_name = this.$route.name;
      data.target_path = this.$route.path;
      data.route = this.current_route;

      //console.log(data);
    },
    loginCheckInterval() {
      this.interval = setInterval(() => this.sendLoginCheck(), 60000);
    },
    sendLoginCheck() {

      //console.log('sendLoginCheck');

      //todo: local development
      var cookie = this.$store.getters.getToken;

      axios.post(
          this.API_URL,
          {
            type: 'check_is_logged_in',
            cookie: cookie,
          }
      ).then((response) => {
        //console.log(response);

        //zum Testen
        /*
        this.intervalCounter++;

        if (this.intervalCounter >= 3) {
          this.$store.dispatch('setToken', '');
        }
         */

        if ((
                "is_logged_id" in response.data && response.data.is_logged_id === false
            )
            || ("error" in response.data)) {

          //console.log('Auto-Abmeldung');
          this.$store.dispatch('setIsLoggedIn', false);
          clearInterval(this.interval);
          window.location = '/';

        }

      }, (error) => {
        console.log(error);
        this.$store.dispatch('setIsLoggedIn', false);
      });

    },
    async trackData(data) {

      //from ist leider nicht immer richtig, insbesondere, wenn params mit übergeben werden
      let from = this.getFromRoute;
      let to = this.getToRoute;
      //let current = this.$router.currentRoute;
      let locationOrigin = window.location.origin;

      var source = data.source;
      var target = data.target;
      var button = data.button;

      if (source === null) {
        source = locationOrigin + from.fullPath;
      } else if (source.charAt(0) == '/') {
        source = locationOrigin + source;
      }

      if (target === null) {
        target = locationOrigin + to.fullPath;
      } else if (target.charAt(0) == '/') {
        target = locationOrigin + target;
      }

      /*
      console.log('called');
      console.log('data', data);
      console.log('from', this.getFromRoute);
      console.log('to',this.getToRoute);
      console.log('source', source);
      console.log('target', target);
      console.log('current', current.fullPath);
      console.log('button', button);
      //console.log(window.location);
      //console.log(window.location.origin);
      //console.log(window.location.host);
       */

      //todo: local development
      var cookie = this.$store.getters.getToken;

      await axios.post(
          this.API_URL,
          {
            type: 'track_data',
            cookie: cookie,
            source: source,
            target: target,
            title: document.title,
            button: button,
          }
      );
    },
  },
  watch: {
    getIsLoggedIn() {

      if (this.getIsLoggedIn) {

        this.checkLogin;

      }

    }
  }
}
</script>

<style>




.link-primary {
  color: #5d4498;
}

.link-primary:visited,
.link-primary:active,
.link-primary:link,
.link-primary:focus,
.link-primary:focus-within,
.link-primary:focus-visible
{
  color: #5d4498 !important;
}

.link-primary:hover
{
  color: #a28ad9 !important;
}

.link-primary-modal {
  color: #a28ad9;
}

.link-primary-modal:visited,
.link-primary-modal:active,
.link-primary-modal:link,
.link-primary-modal:focus,
.link-primary-modal:focus-within,
.link-primary-modal:focus-visible
{
  color: #a28ad9 !important;
}

.link-primary-modal:hover
{
  color: #ffffff !important;
}

.little-button,
.little-button:visited,
.little-button:active,
.little-button:link,
.little-button:focus,
.little-button:focus-within,
.little-button:focus-visible,
.little-button:target {
  background: linear-gradient(0deg,#5d4498,#c5b7e8) !important;
  border: none !important;
  border-radius: 5px !important;
  color: #fff !important;
  font-family: 'Ubuntu';
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0rem;
  margin-right: 0;
  padding: 0;
  text-decoration: none;
  text-shadow: 1px 1px 2px rgba(0,0,0,.5);
}


.little-button:hover
{
  background: #a28ad9 !important;
  border: none !important;
  border-radius: 5px !important;
  color: #fff !important;
  font-family: 'Ubuntu';
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0rem;
  margin-right: 0;
  padding: 0;
  text-decoration: none;
  text-shadow: 1px 1px 2px rgba(0,0,0,.5);
}



s {
  color: red;
  margin-right: 5px;
}

.cursor-pointer {
  cursor: pointer;
}
</style>
