<template>
  <b-container fluid="xxl">

    <b-row class="mb-5">
      <b-col cols="12" md="8" xl="6" cs="5" >
        <h6 class="font-light">{{ productName }}</h6>
        <component :is="dynamicHeading">{{ $t('MY_PROJECTS') }}</component>
        
      </b-col>
    </b-row>

    <b-row class="mb-7">
      <b-col cols="12" md="8" >
        <p>Wie Du Deine Checkliste richtig verwalten kannst, erklären wir Dir hier.</p>
        <p><b-button
            type="button"
            variant="cta"
            v-b-modal="'video-anleitung'"
            @click="$root.$emit('track', {source: null, target: null, button: 'Checkliste Übersicht Video-Anleitung'})"
        >
          {{ $t('QUICK_TIP') }}
        </b-button></p>

        <b-modal id="video-anleitung" size="lg"  :centered="true" :hide-footer="true" :title="$t('QUICK_TIP_TITLE')">
          <div class="wl-external-video">
            <div class="wl-external-video__inner">
              <div class="wl-external-video__main">
                <div><iframe src="https://player.vimeo.com/video/775124521?h=b0800454aa&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="checklisten-overview.mov"></iframe>
                </div>

              </div>
            </div>
          </div>

          <!--<div style="padding:75% 0 0 0;position:relative;border-radius: 15px;"><iframe src="https://player.vimeo.com/video/775124521?h=b0800454aa&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="checklisten-overview.mov"></iframe></div>-->
        </b-modal>
      </b-col>
    </b-row>


    <b-row>
      <b-col cols="12">
        <div v-if="checklists.length > 0">
          <div class="mb-5" v-for="(checklist, checklist_index)  in checklists"  :key="checklist_index">
            <hocTask 
              :title="checklist.checklist.checklist_name" 
              :open="true" 
              :show-completion="false"
              :always-open="true" 
              :url="`/myChecklistOverview/${checklist.checklist.company_checklist_id}`"
              v-on:submit="handleSliderTaskEvent(checklist)"
            >
            
                <small class="d-block mb-2"><strong>{{ $t('CREATED_AT') }}:</strong> {{ checklist.checklist.activation_date_formatted }}</small>
                <h6 class="mb-4">{{ $t('PROJECT_INDEX') }}:</h6>
                <uiProgessbar :currentvalue="parseFloat(checklist.statistic.status)" :totalvalue="parseInt(100)"/>
          
            </hocTask>
          </div>
        </div>

        <p v-else>{{ $t('NO_PROJECTS_IN_YOUR_LIST') }}</p>
    
        <b-button @click="trackSaleChecklist()"  type="button" variant="cta" class="button-cta mt-4">
            {{ $t('CREATE_NEW_PROJECT') }}
        </b-button>
      </b-col>

     
    </b-row>


    <b-row
        v-if="upselling.length > 0"
        class="justify-content-md-center mb-3 mt-5">
      <b-col
          cols="12" md="auto">

        <h3>{{$t('PRODUCT_RECOMMONDATIONS')}}</h3>

        <b-row>
         <!--{{upselling}}-->
         <b-col
          v-for="(product, product_index)  in upselling"
          :key="product_index"
          cols="12" xl="6" xxl="4"
          class="mt-4 mb-3"
          >
            <ProductCard
              :no-padding="true"
              :product="product"
              :product-id="product.product_id  | tostring"
              :product-title="product.product_name"
              :product-description="product.product_description_short"
              :product-type="product.product_type"
              :badge-text="badgeTextHandler(product)"
              :product-price="`${product.product_price}`"
              :product-pseudo-price="`${product.product_pseudoprice}`"
              :product-btn-label="handleSliderProductBtnLabel(product)"
              :product-image="$utils.getMediaImg(3, product.media, 'product_media_description_type_id')"
              :product-cta-link="product.product_url"

              v-on:submit="handleSliderProductCardEvent"

              :small-layout="true"


            />

          </b-col>
        </b-row>

      </b-col>
    </b-row>


  </b-container>
</template>

<script>

import {mapActions, mapGetters, mapMutations} from "vuex";
import hocTask from '@/components/hoc/hocTask.vue';
import uiProgessbar from '@/components/ui/uiProgressbar.vue';
import ProductCard from '@/components/ProductCard.vue';
import axios from "axios";
import Vue from 'vue';

export default {
  name: "areaMyChecklistProjects",
  components: {
    hocTask,
    uiProgessbar,
    ProductCard
  },
  props: {
    headline: {
      type:String,
      default:'3'
    }
  },
  data() {
    return {

      API_URL: process.env.VUE_APP_API,
      heading: this.headline,
      productID: -1,
      productName: '',
      checklists: [],
      upselling: []
    }
  },
  mounted () {

    if (!this.$route.params.product_id) {
      
      this.$router.push('/myProducts');
    } else {
      this.productID = this.$route.params.product_id;
    }

    this.loadMyProductList();

  },
  computed: {
    ...mapGetters([
      'getProductList',
      'getProductById',
      'getTokenParamWithTokenMd5',
    ]),
    dynamicHeading() {
      return 'h'+ this.heading
    }
  },
  methods: {
    ...mapMutations([
    ]),
    ...mapActions([
      'setProductList'
    ]),
    ...mapGetters([

    ]),
    async handleSliderTaskEvent(data) {
      if(!data) return;

      //console.log(data);

      var url = "/myChecklistOverview/" + data.checklist.company_checklist_id;
      await this.$root.$emit('track', {source: null, target: url, button: data.product.product_type + ': ' + data.checklist.checklist_name});

      this.$router.push(url);
        
    },

    handleFreeProductCardEvent(data) {

      if(!data || !("type" in data) ) return;

      var purl = this.$utils.checkIfUrlAndAddParam(data.url, this.getTokenParamWithTokenMd5);
      window.open(purl,'_blank');

      /*
      switch(data.type) {
        case 'Whitepaper':
          window.location.href = data.url;
          break;

        case 'Checkliste':
          this.$router.push(data.url);
          break;
      }

       */
    },
    async loadMyProductList() {

      //todo: local development
      var cookie = this.$store.getters.getToken;

      const response = await axios.post(
          this.API_URL,
          {
            type: 'get_myproducts_list',
            cookie: cookie,
          }
      );

      if (!response.data.error) {
        this.$store.dispatch('setMyProductList', response.data);

        const product = this.getProductById(this.productID);

        const { product_name } = product.product;
        this.productName = product_name;
        this.checklists  = product.checklist;
        this.upselling = product.upselling;

      }

    },

    async trackSaleChecklist() {

      await this.$root.$emit('track', {source: null, target: null, button: 'Checkliste: Neues Projekt anlegen (' + this.productID + ')'});

      //todo: local development
      var cookie = this.$store.getters.getToken;

      await axios.post(
          this.API_URL,
          {
            type: 'track_sale_checklist',
            cookie: cookie,
            product_id: this.productID,
          }
      );

      this.loadMyProductList();

    },


    badgeTextHandler(product) {

      //console.log(product);

      var label = '';

      if (product.product_price > 0) {
        label = Vue.utils.formatPrice(product.product_price);
      } else {
        label = this.$t('FREE');
      }

      return label;

    },

    handleSliderProductBtnLabel(product) {

      //console.log(product);

      var label = '';

      switch(product.product_type) {
        case 'Whitepaper':
          label = this.$t('FREE_OF_CHARGE_DOWNLOAD');
          break;

        case 'Checkliste':
          if (product.product_price > 0) {
            /*
            label = '';
            if (product.product.product_pseudoprice > 0) {
              label = "<s>" + Vue.utils.formatPrice((product.product.product_pseudoprice)) + "</s>";
            }
            label += Vue.utils.formatPrice((product.product.product_price));

             */
            label = this.$t('MORE_DETAILS');
          } else {
            label = this.$t('FREE_OF_CHARGE_ACCESS');
          }
          break;

        case 'Onlinekurs':
          if (product.product_price > 0) {
            /*
            label = '';
            if (product.product.product_pseudoprice > 0) {
              label = "<s>" + Vue.utils.formatPrice((product.product.product_pseudoprice)) + "</s>";
            }
            label += Vue.utils.formatPrice((product.product.product_price));

             */
            label = this.$t('MORE_DETAILS');
          } else {
            label = this.$t('GO_TO_LEARNING_CONTENT');
          }
          break;

        case 'Support':
          label = this.$t('MORE_DETAILS');
          break;

        case 'Mastermind':
          label = this.$t('MORE_DETAILS');
          break;


      }

      //console.log(label, product.product_name);

      return label;


    },
    async handleSliderProductCardEvent(data) {
      if(!data || !("type" in data) ) {
        return;
      }

      var url = '';
      var purl = '';
      //console.log(data);
      //console.log(data.type);

      switch(data.type) {

        case 'Whitepaper':
          await this.$root.$emit('track', {source: null, target: data.product['whitepaper'].media[0].url, button: data.type + ': ' + data.product.product_name});
          //console.log(data.product['whitepaper'].media[0].url);
          this.trackSale(data.id, data.product['whitepaper'].media[0].url);
          break;

        case 'Checkliste':
          if (("product" in data)
              && data.product.product_price > 0
          ) {
            await this.$root.$emit('track', {source: null, target: data.product.product_url, button: data.type + ': ' + data.product.product_name});
            //zur Kaufseite
            purl = this.$utils.checkIfUrlAndAddParam(data.product.product_url, this.getTokenParamWithTokenMd5);
            window.open(purl,'_blank');
          } else {
            url = '/myChecklistProjects/' + data.product.product_id;
            await this.$root.$emit('track', {source: null, target: url, button: data.type + ': ' + data.product.product_name});
            //Gratisliste erwerben
            this.trackSaleChecklistUpselling(data.product.product_id, url);
          }
          break;

        case 'Onlinekurs':
          if (("product" in data)
              && data.product.product_price > 0
          ) {
            await this.$root.$emit('track', {source: null, target: data.product.product_url, button: data.type + ': ' + data.product.product_name});
            //zur Kaufseite
            purl = this.$utils.checkIfUrlAndAddParam(data.product.product_url, this.getTokenParamWithTokenMd5);
            window.open(purl,'_blank');
          } else {
            url = '/academylesson/' + data.product.product_id;
            await this.$root.$emit('track', {source: null, target: url, button: data.type + ': ' + data.product.product_name});
            //Gratis erwerben
            this.trackSaleCourse(data.product.product_id, url);
          }
          break;

        case 'Support':
        case 'Mastermind':
          if (("product" in data)
              && data.product.product_price > 0
          ) {
            await this.$root.$emit('track', {source: null, target: data.product.product_url, button: data.type + ': ' + data.product.product_name});
            //zur Kaufseite
            purl = this.$utils.checkIfUrlAndAddParam(data.product.product_url, this.getTokenParamWithTokenMd5);
            window.open(purl,'_blank');
          }
          break;
      }


    },
    async trackSale(product_id, url) {

      //todo: local development
      var cookie = this.$store.getters.getToken;

      await axios.post(
          this.API_URL,
          {
            type: 'track_sale',
            cookie: cookie,
            product_id: product_id,
          }
      );

      this.$store.dispatch('updateMyProductIsMyProduct', product_id);
      this.emitChanges = true;
      //console.log(url);
      var purl = this.$utils.checkIfUrlAndAddParam(url, this.getTokenParamWithTokenMd5);
      window.location = purl;


    },

    async trackSaleChecklistUpselling(product_id, url) {

      //todo: local development
      var cookie = this.$store.getters.getToken;

      await axios.post(
          this.API_URL,
          {
            type: 'track_sale_checklist',
            cookie: cookie,
            product_id: product_id,
          }
      );

      this.$store.dispatch('updateMyProductIsMyProduct', product_id);
      //this.$router.push('/myChecklistProjects/' + product_id);
      //this.$router.push(url);

      this.productID = product_id;
      this.loadMyProductList();
      this.$router.push(url);

      //this.$router.push({path: '/myChecklistProjects/', params: { product_id: product_id}});

    },

    async trackSaleCourse(product_id, url) {

      //todo: local development
      var cookie = this.$store.getters.getToken;

      await axios.post(
          this.API_URL,
          {
            type: 'track_sale_course',
            cookie: cookie,
            product_id: product_id,
          }
      );

      //this.$router.push('/academylesson/' + product_id);
      this.$router.push(url);

    },


  },
}
</script>
