import axios from "axios";
import Vue from "vue";
import router from "@/routes";
const state = {

    product: {},
    products: [],
    currentLesson: null,
    nextLesson: null,
    iteratorlist: () =>{},
    currentLessonIsPlaying: false,
    API_URL: process.env.VUE_APP_API
   
}

const mutations = {

    SET_VIDEO_PRODUCT(state, payload) {
        state.product = payload;
       
    },

    SET_VIDEO_PRODUCTS(state, payload) {
        state.products = payload;
       
    },

    SET_ITERATOR(state, payload) {
        state.iteratorlist = payload;

    },

    SET_CURRENT_LESSON(state, payload) {
        state.currentLesson = payload.lesson;

    },

    SET_NEXT_LESSON(state, payload) {
        state.nextLesson = payload.lesson;
      
    },

    SET_CURRENT_LESSON_IS_PLAYING(state, payload) {
        state.currentLessonIsPlaying = payload;
    },

    UPDATE_CURRENT_LESSON_TIME(state, payload) {

 
        if(state.product.chapters){
          
            const currentchapter = state.product.chapters[payload.chapterindex];
            currentchapter.lessons = currentchapter.lessons.map(item => {
                

                /** Check Lesson is done  */
                if (item.lesson_id === payload.id) {    
                  
                    return {...item, lesson_current_time: payload.time};
                }
              
                return item;
            });

            state.currentLesson.lesson_current_time = payload.time;

        }

    },

    UPDATE_CURRENT_LESSON_DONE(state, payload) {
       
        if(state.product.chapters){
            const chapterindex = state.product.chapters.findIndex( item => {
                return item.chapter_id === state.currentLesson.chapter_id;
            })

            const currentchapter = state.product.chapters[chapterindex];
            currentchapter.lessons = currentchapter.lessons.map(item => {
                

                /** Check Lesson is done  */
                if (item.lesson_id === payload.id) {    
                    const newValues = {
                        lesson_current_time: payload.time,
                        is_done: true
                    }; 
                   
                    return {...item, ...newValues};
                }
              
                return item;
            });

            
            this.dispatch('checkChapterCompletions', { currentchapter: currentchapter });

        }
       
        
    },

    UPDATE_CURRENT_CHAPTER_DONE(state, payload) {
        if(state.product.chapters){
            state.product.chapters[payload.chapterindex].is_done = payload.is_done;

        }
    },

    UPDATE_CURRENT_LESSON_NOTICE(state, payload) {
        if(state.product.chapters){
          
            const currentchapter = state.product.chapters[payload.chapterindex];
            currentchapter.lessons = currentchapter.lessons.map(item => {
                

                /** Check Lesson is done  */
                if (item.lesson_id === payload.id) {  
                    return {...item, notice: payload.text};
                    
                }
              
                
                return item;
            });

            state.currentLesson.notice = payload.text;

        }
    }

}

const actions = {

    async loadCourses({ commit, rootState }) {
        let cookie = rootState.user.token;
        const response = await axios.post(
            state.API_URL,
            {
              type: 'get_course_list',
              cookie: cookie,
            }
        );
        
        if (response.data.error) {

            commit('SET_VIDEO_PRODUCTS', [])
    
          } else {
            const { videolist } = response.data;
           
            commit('SET_VIDEO_PRODUCTS', videolist)

                
          }
        
        
    },


    async loadCourse({ commit, rootState }, payload) {

        let cookie = rootState.user.token;
        const response = await axios.post(
            state.API_URL,
            {
              type: 'get_course',
              cookie: cookie,
              product_id: payload.id
            }
        );
       
        if (response.data.error) {

            commit('SET_VIDEO_PRODUCT', {})
    
          } else {
            

            const [first] = response.data.videolist.filter((item) => {
                return item.product_id == payload.id
            });
           
            const { chapters } = first;
            
            const list = chapters.map(chapter =>
                chapter.lessons.map(lesson => ({ chapter_id: chapter.chapter_id, hash: Vue.utils.generateRandomHash(15), ...lesson }))
            ).flat();

            const iteratorlist = Vue.utils.customIterator(list);
            const firstlesson = iteratorlist.next().value;
            
            commit('SET_ITERATOR', iteratorlist);
            commit('SET_VIDEO_PRODUCT',first)
          

            const nextlesson =  state.iteratorlist.nextSibling().value;
            commit('SET_NEXT_LESSON', {lesson: nextlesson});
            commit('SET_CURRENT_LESSON', {lesson:firstlesson})
            commit('SET_CURRENT_LESSON_IS_PLAYING', false)
                
          }
        
        
    },

    async trackSaleCourse({rootState}, payload) {
        let cookie = rootState.user.token;
        await axios.post(
            state.API_URL,
            {
                type: 'track_sale_course',
                cookie: cookie,
                product_id: payload.id,
            }
        );

        router.push(`/academylesson/${payload.id}`);
    },

    
    setCourse({commit}, payload) {

      
        const [first] = state.products.filter((item) => {
            return item.product_id == payload.id
        });

      

        const { chapters } = first;
            const list = chapters.map(chapter =>
                chapter.lessons.map(lesson => ({ chapter_id: chapter.chapter_id, hash: Vue.utils.generateRandomHash(15), ...lesson }))
            ).flat();

        const iteratorlist = Vue.utils.customIterator(list);
        const firstlesson = iteratorlist.next().value;
       
        commit('SET_ITERATOR', iteratorlist);
        commit('SET_VIDEO_PRODUCT', first);
        

        const nextlesson =  state.iteratorlist.nextSibling().value;
        commit('SET_NEXT_LESSON', {lesson: nextlesson});
        commit('SET_CURRENT_LESSON', {lesson:firstlesson})
        commit('SET_CURRENT_LESSON_IS_PLAYING', false)
        
    },

    setCurrentLesson({commit}, payload) {

        const currentlesson = (payload.lid && payload.cid) ?  state.iteratorlist.nextByProp('chapter_id', 'lesson_id', payload.cid, payload.lid).value : state.iteratorlist.next().value;
        const nextlesson =  state.iteratorlist.nextSibling().value;
        commit('SET_NEXT_LESSON', {lesson: nextlesson});
        commit('SET_CURRENT_LESSON', {lesson: currentlesson});
        commit('SET_CURRENT_LESSON_IS_PLAYING', payload.shouldplay)
    },

    setCurrentLessonPlaying({commit}, payload) {
        commit('SET_CURRENT_LESSON_IS_PLAYING', payload);
    },

    async updateLessonNotice({commit, rootState}, payload) {

        let cookie = rootState.user.token;  

        const chapterindex = state.product.chapters.findIndex( item => {
            return item.chapter_id === state.currentLesson.chapter_id;
        })   

        await axios.post(
            state.API_URL,
            {
              type: 'update_user_course_item_notice',
              user_course_item_id: payload.id,
              notice: payload.text,
              cookie: cookie,
            }
        );

        commit('UPDATE_CURRENT_LESSON_NOTICE', {...payload, chapterindex: chapterindex})

    },


    async updateCurrentLesson({commit, rootState}, payload) {
    
         let cookie = rootState.user.token;  

         const chapterindex = state.product.chapters.findIndex( item => {
            return item.chapter_id === state.currentLesson.chapter_id;
        })         

         await axios.post(
             state.API_URL,
             {
               type: 'update_user_course_item_position',
               user_course_item_id: payload.id,
               position: payload.time,
               cookie: cookie,
             }
         );

       

        commit('UPDATE_CURRENT_LESSON_TIME', {...payload, chapterindex: chapterindex})
    },

    async checkLessonCompletions({commit, rootState}, payload) {
        let cookie = rootState.user.token;    
        
        const chapterindex = state.product.chapters.findIndex( item => {
            return item.chapter_id === state.currentLesson.chapter_id;
        })

        await axios.post(
            state.API_URL,
                {
                type: 'update_user_course_item_is_done',
                user_course_item_id: payload.id,
                is_done: true,
                position: payload.time,
                cookie: cookie,
                }
        );    
        commit('UPDATE_CURRENT_LESSON_DONE', {...payload, chapterindex: chapterindex})    
    },

    async checkChapterCompletions({commit, rootState}, payload) {
        let cookie = rootState.user.token;    
        
        const chapterindex = state.product.chapters.findIndex( item => {
            return item.chapter_id === payload.currentchapter.chapter_id;
        })

        /** Check Chapter is done  */
        const chapterisdone = state.product.chapters[chapterindex].lessons.filter(item => {
            return item.is_done;
        }).length === state.product.chapters[chapterindex].lessons.length;


        if(chapterisdone) {

            await axios.post(
                state.API_URL,
                {
                    type: 'update_user_course_is_done',
                    user_course_id: payload.currentchapter.chapter_id,
                    is_done: chapterisdone,
                    cookie: cookie,
                }
            );    

            commit('UPDATE_CURRENT_CHAPTER_DONE', {chapterindex: chapterindex, is_done: chapterisdone})    
        }
        
    }
   
}



const getters = {

    getProducts() {
        return state.products;
    },
    
    getProduct() {
        return state.product
    },

    getLessons() {
        return state.product.chapters;
    },

    getCurrentChapter() {
        if(state.product.chapters){
            return state.product.chapters.filter( item => {
                return item.chapter_id === state.currentLesson.chapter_id
            });
        } else {
            return []
        }
    },

    getCurrentLesson() {

        if(state.product.chapters && Object.keys(state.product.chapters).length > 0){
            const chapterindex = state.product.chapters.findIndex( item => {
                return item.chapter_id === state.currentLesson.chapter_id;
            })
            const [first] = state.product.chapters[chapterindex].lessons.filter( item => item.lesson_id === state.currentLesson.lesson_id)
            return [ { ...state.currentLesson, is_done: first.is_done, lesson_current_time: first.lesson_current_time }];
        } else {
            return []
        }
    },

    getNextLesson() {

        if(state.product.chapters && Object.keys(state.product.chapters).length > 0){

            const chapterindex = state.product.chapters.findIndex( item => {
                return item.chapter_id === state.nextLesson.chapter_id;
            })

            const [first] = state.product.chapters[chapterindex].lessons.filter( item => item.lesson_id === state.nextLesson.lesson_id)
            return [ { ...state.nextLesson, is_done: first.is_done , lesson_current_time: first.lesson_current_time }];
        } else {
            return []
        }
       
    },

    isLessonComplete: (state) => (id, cid) => {
        if(state.product.chapters){
            const chapterindex = state.product.chapters.findIndex( item => {
                return item.chapter_id === cid;
            })

            const [first] = state.product.chapters[chapterindex].lessons.filter( item => item.lesson_id === id)
            return first.is_done;
        } else {
            return false;
        }
    },

    isChapterComplete : (state) => (id) => {
        if(state.product.chapters){
           
            const chapter = state.product.chapters.filter( item => {
                return (item.chapter_id === id && item.is_done)
            });

            return !!chapter.length;
        } else {
            return false;
        }
    },
    getCurrentLessonPlaying() {
        return state.currentLessonIsPlaying;
    },

    getChapterProgress: (state) => (id) => {
       
        const [first] = state.product.chapters.filter((item) => {
            return item.chapter_id === id
        })
        const completelessons = first.lessons.filter((item) => item.is_done  );
        return 100 / first.lessons.length * completelessons.length;
        
    }    

}

const academy = {
    state,
    mutations,
    actions,
    getters

}

export default academy;