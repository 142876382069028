<template>
  <div v-if="getIsLoggedIn">
    <viewHeader />
    <b-container fluid="xxl" class="mb-8 mb-xxl-6 position-relative align-items-stretch d-flex parent-container">
      
      <hocSticky class="wl-sticky__nav-sticky">
          <NavigationBar></NavigationBar>
      </hocSticky>

      <hocCard :shadow="false">
       
        <template #default>
           <b-container fluid="xxl">
            <b-row>
              <b-col cols="12" :xl="showSidBar ? 8 : 12" :xxl="showSidBar ? 9 : 12" class="p-xxl-5 p-xl-4 p-2">
                <slot />
              </b-col>
              <b-col cols="12" v-if="showSidBar" xl="4" xxl="3">
                <aside id="sidebar" class="sidebar h-100 p-2 pt-4" >
                   <component :is="sidebar">
                        <slot />
                    </component>
                </aside>
              </b-col>
            </b-row>
          
            </b-container>
          
        </template>               
      </hocCard>
    </b-container>
   
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import viewHeader from "@/views/viewHeader";
import hocCard from '@/components/hoc/hocCard';
import NavigationBar from "@/components/NavigationBar";
import hocSticky from '@/components/hoc/hocSticky.vue';
import viewTasks from '@/views/viewTasks';

const CONFIG =  require(`@/wl-config/${process.env.VUE_APP_WL_FOLDER}/wl-app-config`)

export default {
  name: "DefaultLayout",
  data() {
    return{
        sidebar: viewTasks,
        showSidBar: false,
        wlAppConstants: CONFIG.WLCONFG
    }
  },
  components: {
    viewHeader,
    hocCard,
    hocSticky,
    NavigationBar,

  },
  watch: {
    $route: {
      immediate: true,
      async handler(route) {
        try {
          const component = await import(`@/views/${route.meta.sidebar}.vue`)
          console.log();

          if(route.meta.showSidebar) {
            this.showSidBar = true;
          } else {
              if( this.wlAppConstants.ACCESSLEVEL == '40' && this.getUserLevel == '40') {
                this.showSidBar = true;
              } else {
                this.showSidBar = false;
              }
          }
     
          this.sidebar = component?.default || viewTasks
        } catch (e) {
          this.sidebar = viewTasks
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'getIsLoggedIn',
      'getUserLevel'
    ]),
  }
}
</script>

<style scoped>
</style>