<template>
     <b-container fluid="xxl">
        <b-row class="mb-4">
            <b-col cols="12" >
                <b-button
                type="button"
                variant="cta"
                class="button-cta rounded-circle icon"
                :to="'/academylessons'"
                ><font-awesome-icon icon="fa-solid fa-caret-left" /></b-button>

            </b-col>

        </b-row>

        <b-row>
            <b-col cols="12" md="8">
                <h6 class="font-light">{{ $t('ACADEMY') }}</h6> 
                <h3>{{productTitle}}</h3>
                <p class="mb-5">{{productDescription}}</p>
            </b-col>
        </b-row>

        <b-row>
            <b-col cols="12">
                <div  class="position-relative">

                    <div class="wl-videolesson__overlay d-flex flex-column align-items-center justify-content-center" :class="showOverlay ? 'wl-videolesson__overlay--active': ''">

                        <div class="text-center" v-for="(next, index) in nextLesson" :key="index">
                       
                            <h6 class="font-light">{{ $t('NEXT_LESSON') }}</h6>
                            <h4 class="mb-3">{{next.lesson_title}}</h4>
                            <div class="wl-videolesson__overlay__cta" @click="handleProgressClickEvent">
                                <b-img :src="next.lesson_poster" fluid class="wl-videolesson__overlay__thumbnail"></b-img>
                                <div class="wl-videolesson__overlay__timer">
                                    <uiCircularTimer :stroke-color="loaderStyle" ref="nextLesson" v-on:finished="handleProgessFinishEvent"/>
                                </div>
                            </div>
                        </div>

                    </div>
                    <template v-if="getType == 'Video'">
                        <div v-for="(lesson, index) in getCurrentLesson" :key="index">
                            <uiVideoPlayer ref="vplayer"
                                :videoid="lesson.lesson_id"
                                :should-play.sync="isPlaying"
                                :ref-hash.sync="lesson.hash"
                                :use-call-back="true"
                                :currenttime.sync="lesson.lesson_current_time"
                                v-on:update="updateLesson"
                                v-on:ready="initLesson"
                                v-on:played="onPlaying"
                                v-on:paused="onPaused"
                                v-on:ended="onEnded"
                                :video.sync="lesson.lesson_src" 
                                :poster.sync="lesson.lesson_poster"
                                controls="~@/assets/mejs-controls.svg"
                            />
                        </div>
                    </template>

                    <template v-if="getType == 'Audio'">
                        <div v-for="(lesson, index) in getCurrentLesson" :key="index">
                            <uiAudioPlayer ref="aplayer"
                                :audioid="lesson.lesson_id"
                                :should-play.sync="isPlaying"
                                :ref-hash.sync="lesson.hash"
                                :use-call-back="true"
                                :currenttime.sync="lesson.lesson_current_time"
                                v-on:update="updateLesson"
                                v-on:ready="initLesson"
                                v-on:played="onPlaying"
                                v-on:paused="onPaused"
                                v-on:ended="onEnded"
                                :audio.sync="lesson.lesson_src"
                                :poster.sync="lesson.lesson_poster"
                            />
                        </div>
                    </template>
                </div>
            </b-col>
        </b-row>
        <b-row class="mt-5">
            <b-col cols="12">
                
                <b-tabs active class="wl-tabs wl-tabs__videotabs">

                
                    
                     <b-tab :title="$t('DESCRIPTION')">
                        <b-container fluid="xxl">
                            <b-row class="mb-7 mt-3" v-for="(lesson, index) in getCurrentLesson" :key="index">
                                <b-col cols="12" xxl="8">
                                    <div class=" mt-3" v-html="lesson.lesson_description"></div>
                                </b-col>
                            </b-row>
                        </b-container>
                       
                    </b-tab>
                    
                    
                    <b-tab :title="$t('NOTES')"  class="position-relative">
                        <b-container fluid="xxl">
                            <b-row class="mb-7 mt-3" v-for="(lesson, index) in getCurrentLesson" :key="index">
                                <b-col cols="12" xl="8">
                                    <div class="d-flex align-items-center w-100">

                                        <div class="p-4">
                                            <b-button
                                                type="button"
                                                variant="cta"
                                                class="button-cta rounded-circle icon small"
                                                @click="updateNotice(lesson.lesson_id)"
                                            >
                                            <font-awesome-icon :icon="(isEditNotice) ? 'fa-solid fa-check': 'fa-solid fa-pencil'" /></b-button>
                                        </div>
                                        
                                        <div class="w-100">
                                            
                                            <p class="mb-0" v-if="!isEditNotice"><small>{{ handleNotice }}</small></p>
                                            <div v-else>
                                                <b-input-group>

                                                <b-form-textarea
                                                    name="lessonNotice"
                                                    id="lessonNotice"
                                                    type="text"
                                                    size="sm"
                                                    :placeholder="$t('INSERT_YOUR_NOTES')"
                                                    rows="8"
                                                    v-model.trim="handleNotice"
                                                ></b-form-textarea>

                                                </b-input-group>
                                            </div>
                                        </div>
                                    </div>
                                </b-col>
                            </b-row>
                        </b-container>
                    </b-tab>
                </b-tabs>
                
            </b-col>
       </b-row>
     </b-container>
</template>


<script>
    import {mapActions, mapGetters} from "vuex";
    import uiVideoPlayer from '@/components/ui/uiVideoPlayer';
    import uiAudioPlayer from '@/components/ui/uiAudioPlayer';
    import uiCircularTimer from "./ui/uiCircularTimer.vue";

    export default {
        name: "areaAcademyVideo",
        components: {
           uiVideoPlayer,
            uiCircularTimer,
            uiAudioPlayer
        },
        data() {
            return {
              API_URL: process.env.VUE_APP_API,
              showOverlay: false,
              cancleTimer:false,
              isEditNotice: false,
              lessonNotice: '',
            }
        },
        mounted() {

            if (!this.$route.params.video_id) {
              this.$router.push('/academylessons');
            } else {
              this.loadCourse({id: this.$route.params.video_id});
              this.lessonNotice =  this.handleNotice;
            }

            
        },
        computed: {
        ...mapGetters( {
                getProduct : 'getProduct',
                isPlaying: 'getCurrentLessonPlaying',
                getCurrentLesson: 'getCurrentLesson',
                nextLesson : 'getNextLesson'
            }),
            loaderStyle() {
                const root = document.querySelector(':root');
                return getComputedStyle(root).getPropertyValue('--cta')
                
            },

            productTitle: {
                get() {
                    return this.getProduct.product_title
                }
            },
            productDescription: {
                get() {
                    return this.getProduct.product_description
                }
            },
            getType : {
                get() {
                    return this.getCurrentLesson[0]?.type 
                }
            },

            handleNotice : {
                get() {
                    return this.getCurrentLesson[0]?.notice || this.$t('INSERT_YOUR_NOTES')
                },

                set(value) {
                   this.lessonNotice = value
                }
            }

        },
        methods: {
          ...mapActions([
                'loadCourse',
                'updateCurrentLesson',
                'setCurrentLessonPlaying',
                'setCurrentLesson',
                'checkLessonCompletions',
                'updateLessonNotice'
          ]),

          initLesson() {
              this.showOverlay = false;
              const timer  = this.$refs.nextLesson[0];
              timer.stop();
              this.lessonNotice =  this.handleNotice;
          },

          updateLesson(data) {
                this.updateCurrentLesson(data)
          },
          onPlaying() {
                this.setCurrentLessonPlaying(true)
          },
          onPaused(data) {
                this.updateCurrentLesson(data)
          },
          handleProgessFinishEvent() {
              this.showOverlay = false;
              this.setCurrentLesson({shouldplay: !this.nextLesson[0]?.is_done})

          },
          onEnded(data) {
              this.checkLessonCompletions(data)
              this.setCurrentLessonPlaying(false);
              this.showOverlay = true;
              const timer  = this.$refs.nextLesson[0];
              timer.start();
          },
          handleProgressClickEvent(){

              this.showOverlay = false;
              const timer  = this.$refs.nextLesson[0];
              timer.stop();
              this.setCurrentLesson({shouldplay: !this.nextLesson[0]?.is_done})
          },

          updateNotice(lesson_id) { 
            const timer  = this.$refs.nextLesson[0];
            if (!this.isEditNotice) {
               
                switch(this.getType) {
                    case 'Audio': {
                        const {aplayer} = this.$refs;
                        aplayer[0].setPause();
                        break;
                    }
                     
                    
                    case 'Video': {
                        const {vplayer} = this.$refs;
                        vplayer[0].setPause();
                        break;
                    }
                      
                }
                if(this.showOverlay) timer.stop()
                this.isEditNotice = true;
            } else {
                this.isEditNotice = false;
                this.updateLessonNotice({id: lesson_id, text: this.lessonNotice})                
                if(this.showOverlay) timer.start()

            }
           

          },


        }
    }
</script>

<style scoped>

</style>