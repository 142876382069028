// For more Icons take a lokk at https://fontawesome.com/

const WLCONFG= {
    SHOW_BLOG:                      false,
    USE_CONTENT_IMAGE_ERROR_PAGE:   true,
    SHOW_DASHBOARD_SIDEBAR:         false,
    SHOW_BLOG_SIDEBAR:              true,
    SHOW_BLOG_ARTICLE_SIDEBAR:      true,
    SHOW_ACADEMY_SIDEBAR:           true,
    SHOW_ACADEMY_LESSON_SIDEBAR:    true,
    SHOW_PRODUCT_SIDEBAR:           true,
    SHOW_CHECKLIST_SIDEBAR:         true,
    SHOW_STATISTIC_SIDEBAR:         false,
    ACCESSLEVEL:                    '40',
    ICON_NAVIGATION_DASHBOARD:      'gauge-high',
    ICON_NAVIGATION_MY_PRODUCTS:    'cart-arrow-down',
    ICON_NAVIGATION_STATISTICS:     'chart-pie',
    ICON_NAVIGATION_ACADEMY:        'graduation-cap',
    ICON_NAVIGATION_BLOG:           'book',
    ICON_NEWS:                      'bullhorn',
    ICON_CHARTS:                    'chart-area',
    ICON_OFFER:                     'basket-shopping',
    LOGO_SIGNET:                    'logo_signet.svg',
    LOGO:                           'logo_variant_2.svg',
    ERROR_LOGO:                     'error_logo.svg',
    APP_META_TITLE:                 'myShopBooster',
    FAVICON_FOLDER:                 'sb',
    META_SAFE_EQUIV:                'member.myshopbooster.de',
    COMPANY_NAME:                   'Shopentwickler E-Commerce GmbH',    
    COMPANY_SHORT_NAME:             'MyShopBooster',
    COMPANY_ADDRESS:                'Ringbahnstr. 12, 12099 Berlin, Deutschland',
    COMPANY_EMAIL:                  'service[@]myshopbooster.de',
    COMPANY_PHONE:                  '030 - 609 83 09 63'
}

export  {
    WLCONFG
}
